<template>
  <div class="tablePart">
    <el-table
      ref="table"
      v-loading="tableLoading"
      :data="finalData"
      border
      :span-method="spanMethod"
      :row-class-name="rowClassName"
      @selection-change="selectionChange"
    >
      <el-table-column type="selection" :selectable="selectable"></el-table-column>
      <el-table-column label="产品信息" prop="orderCode" min-width="200">
        <template slot-scope="{ row }">
          <template v-if="row.$isTotal"> 已选中订单数量: {{ selectionTotal }} </template>
          <template v-else-if="row.isHeader">
            <div class="flex-between">
              <div>
                <span>
                  订单号：<hoverCopy :value="row.orderCode" class="text-primary">{{ row.orderCode }}</hoverCopy>
                </span>
                <span class="ml20">收货人：{{ getConsigneeName(row.orderInfo) }}</span>
              </div>
            </div>
          </template>
          <template v-else>
            <productInfo
              :data="row.orderItemDTOList"
              :orderData="row"
              :doLayout="doLayout"
              :sup_this="sup_this"
            ></productInfo>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="业务员">
        <template slot-scope="{ row }"
          >{{ row.userName }}
          <span v-if="row.userStatus === 2" style="color: red">(已注销)</span>
        </template>
      </el-table-column>
      <el-table-column label="收货地址">
        <template slot-scope="{ row }">
          {{ address(row) }}
        </template>
      </el-table-column>

      <el-table-column label="物流方式">
        <template v-if="row.freightPaymentType" slot-scope="{ row }">
          {{ row.expressShippingMethodCnName || row.fbaTransport }}
        </template>
      </el-table-column>

      <el-table-column label="运费">
        <template slot-scope="{ row: { externalOrderFinance: { paymentFreight } = {}, freightPaymentType } = {} }">
          {{ paymentFreight == null || !freightPaymentType ? '暂无' : parsePrice(paymentFreight) }}
        </template>
      </el-table-column>

      <el-table-column label="实际运费">
        <template slot-scope="{ row: { externalOrderFinance: { finalFreight } = {}, freightPaymentType } = {} }">
          {{ finalFreight == null || !freightPaymentType ? '暂无' : parsePrice(finalFreight) }}
        </template>
      </el-table-column>

      <el-table-column label="运单号">
        <template slot-scope="{ row }">
          {{ row.expressWaybillIsCreated ? row.expressWaybillCode : '暂无' }}
        </template>
      </el-table-column>

      <el-table-column label="时间" width="150">
        <template slot-scope="{ row }">导入时间：{{ row.orderTime }}</template>
      </el-table-column>

      <el-table-column label="状态">
        <template slot-scope="{ row: { orderStatus, unpaidStatus } }">{{
          getOrderStatus(orderStatus, unpaidStatus)
        }}</template>
      </el-table-column>

      <el-table-column label="操作" align="center" width="250">
        <template slot-scope="{ row }">
          <Detail v-if="permission.detail" :data="row" />
          <DownInvoice v-if="permission.downloadInvoice && row.freightPaymentTime" :data="row" />
        </template>
      </el-table-column>

      <template slot="empty">暂无数据</template>
    </el-table>
  </div>
</template>

<script>
import Detail from './detail'
import productInfo from '../productInfo'
import hoverCopy from '@/views/components/hoverCopy'
import DownInvoice from '@/views/components/downInvoice'
import { parseTime, parsePrice } from '@/utils'

import {
  WAIT_DISPATCH, // 待排单
  IN_DISPATCH, // 排单中
  EXPRESS_COMPANY_LIST,
  ORDER_STATUS_LIST,
  EXPIRED,
  WAIT_PAYMENT,
  NO_PAID,
  HAS_PAID,
  IN_PRODUCTION // 生产中
} from '@/utils/constant'

export default {
  components: {
    hoverCopy,
    DownInvoice,
    productInfo,
    Detail
  },
  props: {
    orderListData: {
      type: Array,
      required: true,
      default: []
    },

    permission: {
      type: Object
    },
    tableLoading: {
      type: Boolean,
      default: false
    },
    sup_this: {
      type: Object,
      required: true
    },
    orderStatus: {
      type: String
    }
  },
  data() {
    return {
      factoryData: [],
      EXPRESS_COMPANY_LIST,
      ORDER_STATUS_LIST,
      EXPIRED,
      // 英国税号
      britain_duty_paragraph: '',
      // ioss 税号
      ioss_duty_paragraph: '',
      IN_PRODUCTION,
      WAIT_DISPATCH, // 待排单
      IN_DISPATCH, // 排单中
      dialogVisible: false,
      dialogData: null,
      loading: false
    }
  },
  computed: {
    finalData() {
      const data =
        this.orderListData && this.orderListData.length
          ? [
              {
                $isTotal: true
              }
            ].concat(this.orderListData)
          : null
      return data
    },
    selectionTotal({ factoryData }) {
      return factoryData.length
    },
    getConsigneeName() {
      return (row) => {
        return $GET(row, 'externalOrderLogistics', null)?.consigneeName || '暂无'
      }
    },
    address() {
      return (row) => {
        return $GET(
          row,
          'externalOrderLogistics.consigneeDetailAddress',
          $GET(row, 'externalOrderLogistics.consigneeCountryCode', '暂无')
        )
      }
    }
  },
  methods: {
    parseTime,
    parsePrice,
    getOrderStatus(orderStatus, unpaidStatus) {
      if (orderStatus == WAIT_PAYMENT && unpaidStatus == NO_PAID) return '未支付'
      else if (orderStatus == WAIT_PAYMENT && unpaidStatus == HAS_PAID) return '待付货款'
      return ORDER_STATUS_LIST[orderStatus]
    },
    selectionChange(selection) {
      this.factoryData = selection
      this.$emit('takeFactory', selection)
    },
    spanMethod({ row, columnIndex }) {
      if (row.$isTotal) {
        if (columnIndex === 1) {
          return [1, 14]
        } else {
          return [0, 0]
        }
      } else if (row.isHeader) {
        if (columnIndex === 0) {
          return [1, 1]
        } else if (columnIndex === 1) {
          return [1, 13]
        } else {
          return [0, 0]
        }
      } else {
        if (columnIndex === 0) {
          return [0, 0]
        } else if (columnIndex === 1) {
          return [1, 2]
        }
      }
      return [1, 1]
    },
    rowClassName({ row }) {
      if (row.$isTotal) {
        return this.selectionTotal ? 'total-row' : 'none'
      }
      if (row.isHeader) return 'hover-row-style is-visible'
    },
    selectable(row) {
      return row.isHeader
    },
    doLayout() {
      return this.$refs.table.doLayout()
    }
  }
}
</script>

<style lang="scss" scoped>
.tablePart {
  margin-top: 20px;
}
.tixing {
  margin-left: 10px;
}
.icon-circle {
  &:nth-last-child(2) {
    &::before {
      background-color: #67c23a;
    }
  }
}
</style>
