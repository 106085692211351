var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tablePart"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],ref:"table",attrs:{"data":_vm.finalData,"border":"","span-method":_vm.spanMethod,"row-class-name":_vm.rowClassName},on:{"selection-change":_vm.selectionChange}},[_c('el-table-column',{attrs:{"type":"selection","selectable":_vm.selectable}}),_c('el-table-column',{attrs:{"label":"产品信息","prop":"orderCode","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.$isTotal)?[_vm._v(" 已选中订单数量: "+_vm._s(_vm.selectionTotal)+" ")]:(row.isHeader)?[_c('div',{staticClass:"flex-between"},[_c('div',[_c('span',[_vm._v(" 订单号："),_c('hoverCopy',{staticClass:"text-primary",attrs:{"value":row.orderCode}},[_vm._v(_vm._s(row.orderCode))])],1),_c('span',{staticClass:"ml20"},[_vm._v("收货人："+_vm._s(_vm.getConsigneeName(row.orderInfo)))])])])]:[_c('productInfo',{attrs:{"data":row.orderItemDTOList,"orderData":row,"doLayout":_vm.doLayout,"sup_this":_vm.sup_this}})]]}}])}),_c('el-table-column',{attrs:{"label":"业务员"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.userName)+" "),(row.userStatus === 2)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("(已注销)")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"收货地址"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.address(row))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"物流方式"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (row.freightPaymentType)?[_vm._v(" "+_vm._s(row.expressShippingMethodCnName || row.fbaTransport)+" ")]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"label":"运费"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row; if ( ref_row === void 0 ) ref_row = {};
var ref_row$1 = ref_row;
var ref_row$1_externalOrderFinance = ref_row$1.externalOrderFinance; if ( ref_row$1_externalOrderFinance === void 0 ) ref_row$1_externalOrderFinance = {};
var paymentFreight = ref_row$1_externalOrderFinance.paymentFreight;
var freightPaymentType = ref_row$1.freightPaymentType;
return [_vm._v(" "+_vm._s(paymentFreight == null || !freightPaymentType ? '暂无' : _vm.parsePrice(paymentFreight))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"实际运费"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row; if ( ref_row === void 0 ) ref_row = {};
var ref_row$1 = ref_row;
var ref_row$1_externalOrderFinance = ref_row$1.externalOrderFinance; if ( ref_row$1_externalOrderFinance === void 0 ) ref_row$1_externalOrderFinance = {};
var finalFreight = ref_row$1_externalOrderFinance.finalFreight;
var freightPaymentType = ref_row$1.freightPaymentType;
return [_vm._v(" "+_vm._s(finalFreight == null || !freightPaymentType ? '暂无' : _vm.parsePrice(finalFreight))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"运单号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.expressWaybillIsCreated ? row.expressWaybillCode : '暂无')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"时间","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("导入时间："+_vm._s(row.orderTime))]}}])}),_c('el-table-column',{attrs:{"label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var orderStatus = ref_row.orderStatus;
var unpaidStatus = ref_row.unpaidStatus;
return [_vm._v(_vm._s(_vm.getOrderStatus(orderStatus, unpaidStatus)))]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.permission.detail)?_c('Detail',{attrs:{"data":row}}):_vm._e(),(_vm.permission.downloadInvoice && row.freightPaymentTime)?_c('DownInvoice',{attrs:{"data":row}}):_vm._e()]}}])}),_c('template',{slot:"empty"},[_vm._v("暂无数据")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }